import { HttpClient, HttpErrorResponse, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, catchError, tap, shareReplay, share } from "rxjs/operators";
import { environment } from 'src/environments/environment';
import { throwError as observableThrowError, Observable, Subject, BehaviorSubject, ReplaySubject, of } from "rxjs";

import { User } from "../models/user.model";

import Swal from 'sweetalert2';

@Injectable({ providedIn: "root" })

export class Services {
  private setStoreRole = new BehaviorSubject<string>("");

  private usersStoreSubject = new BehaviorSubject<User[]>([]);
  private regionSubject = new BehaviorSubject<any[]>([]);
  private workerSpecialtySubject = new BehaviorSubject<any[]>([]);
  private appointmentsDataSubject = new BehaviorSubject<any[]>([]);

  users$: Observable<User[]> = this.usersStoreSubject.asObservable();
  region$: Observable<any[]> = this.regionSubject.asObservable();
  workerSpecialty$: Observable<any[]> = this.workerSpecialtySubject.asObservable();
  role$: Observable<string> = this.setStoreRole.asObservable();
  appointmentsData$ = this.appointmentsDataSubject.asObservable()


  constructor(private http: HttpClient) {
    this.loadUsers();
    this.loadRegion();
    this.loadWorkerSpecialty();
  }

  private loadUsers() {
    const loadUsers$ = this.http.get<User[]>(environment.apiUrl + '/users').pipe(
      map(res => res),
      tap(users => this.usersStoreSubject.next(users)),
      catchError(error => {
        // Handle the error or log it
        console.error('Error loading users:', error);
        // Return an empty array or rethrow the error depending on your handling strategy
        return of([]);
      })
    );
    loadUsers$.subscribe();
  }  private loadRegion() { const loadRegion$ = this.http.get<any[]>(environment.apiUrl + '/region').pipe(map(res => res), tap(region => this.regionSubject.next(region))); loadRegion$.subscribe(); }
  private loadWorkerSpecialty() { const loadSpecialty$ = this.http.get<any[]>(environment.apiUrl + '/workerSpecialty').pipe(map(res => res), tap(specialty => this.workerSpecialtySubject.next(specialty))); loadSpecialty$.subscribe(); }

  setUserRole(role: string) {
    this.setStoreRole.next(role);
  }

  getUsers(): Observable<any> { return this.users$.pipe(map(res => { return res }), catchError(this.handleError)) }

  makeAppointment(data): Observable<any> {
    Swal.fire({
      title: 'Запазване на час моля изчакайте',
      allowOutsideClick: false,
    });
    Swal.showLoading();
    return this.http.patch<any>(environment.apiUrl + '/appointment', data)
      .pipe(map(res => {
        Swal.fire({
          title: 'Успешно запазен час.',
          icon: 'success',
          html: 'Когато майстора го получи ще се свърже с вас.',
          // html: 'Scenario <strong>' + data.title + '</strong> has been added to the database.',
          confirmButtonColor: '#3F51B5',
          allowOutsideClick: false,
        });
        return res
      }), catchError(this.handleError))
  }

  enableAppointment(data): Observable<any> {
    Swal.fire({
      title: 'Задаване на часове',
      allowOutsideClick: false,
    });
    Swal.showLoading();
    return this.http.post<any>(environment.apiUrl + '/enableAppointment', data)
      .pipe(map(res => {
        let hours = [];
        res.forEach(element => {
          hours.push(element.slotTimeStart + " - " + element.slotTimeEnd)
        });
        Swal.fire({
          title: 'Успешно зададени часове',
          icon: 'success',
          html: `Създадени са часове за <strong>${res[0].date}</strong> 
          <br> <strong> ${hours} </strong>`,
          // html: 'Scenario <strong>' + data.title + '</strong> has been added to the database.',
          confirmButtonColor: '#3F51B5',
          allowOutsideClick: false,
        });
        const currentData = this.appointmentsDataSubject.getValue();
        this.appointmentsDataSubject.next(this.sortAppointments([...currentData, ...res]));
        return res
      }), catchError(this.handleError))
  }

  patchWorker(formData: FormData): Observable<any> {

    return this.http.patch<any>(environment.apiUrl + '/workerPatch', formData)
      .pipe(map(res => {
        Swal.fire({
          title: 'Успешно обновяване.',
          icon: 'success',
          // html: 'Scenario has been added to the database.',
          // html: 'Scenario <strong>' + data.title + '</strong> has been added to the database.',
          confirmButtonColor: '#3F51B5',
          allowOutsideClick: false,
        });
        return res
      }), catchError(this.handleError))
  }


  patchUser(data): Observable<any> {
    return this.http.patch<any>(environment.apiUrl + '/userPatch', data)
      .pipe(
        tap(updatedUser => {
          const currentUsers = this.usersStoreSubject.value;
          const userIndex = currentUsers.findIndex(user => user._id === updatedUser._id);

          if (userIndex !== -1) {
            currentUsers[userIndex] = updatedUser;
            this.usersStoreSubject.next(currentUsers);
          }
        }),
        map(res => {
          Swal.fire({
            title: 'Запазени промени.',
            icon: 'success',
            confirmButtonColor: '#3F51B5',
            allowOutsideClick: false,
          });
          return res;
        }),
        catchError(this.handleError)
      );
  }

  patchAppointment(data): Observable<any> {
    return this.http.patch<any>(environment.apiUrl + '/appointmentPatch', data)
      .pipe(map(res => {
        Swal.fire({
          title: 'Успешно обновяване.',
          icon: 'success',
          confirmButtonColor: '#3F51B5',
          allowOutsideClick: false,
        });
        const currentData = this.appointmentsDataSubject.getValue();
        const indexToUpdate = currentData.findIndex(appointment => appointment._id === res._id);

        if (indexToUpdate !== -1) {
          currentData[indexToUpdate].status = res.status;
          currentData[indexToUpdate].comment = res.comment;
          currentData[indexToUpdate].address = res.address;
          this.appointmentsDataSubject.next(this.sortAppointments([...currentData]));
        }

        return res;
      }), catchError(this.handleError))
  }

  cancelAppointment(data): Observable<any> {
    return this.http.patch<any>(environment.apiUrl + '/appointmentPatch', data.appointment)
      .pipe(map(res => {
        Swal.fire({
          title: 'Часа беше отказан успешно.',
          icon: 'success',
          confirmButtonColor: '#3F51B5',
          allowOutsideClick: false,
        });

        return { res, index: data.index }; // Return the index of the cancelled appointment
      }), catchError(this.handleError))
  }


  getUser(userId): Observable<any> {
    return this.http.get(environment.apiUrl + "/user/" + userId).pipe(map(res => { return res }), catchError(this.handleError))
  }

  getWorkerAppointments(workerId: string, startDate?: string, endDate?: string): Observable<any> {
    let params = new HttpParams()
      .set('startDate', startDate)
      .set('endDate', endDate);

    // Include the params object in the HTTP request
    return this.http.get(environment.apiUrl + "/workerAppointments/" + workerId, { params }).pipe(
      map(res => {
        this.appointmentsDataSubject.next(this.sortAppointments(res as any[]));
        return res;
      }),
      catchError(this.handleError)
    );
  }


  getWorkerAppointmentsByDate(workerId: string, date?: string): Observable<any> {
    let params = new HttpParams();

    if (date) {
      params = params.set('date', date);
    }


    // Include the params object in the HTTP request
    return this.http.get(environment.apiUrl + "/workerAppointments/" + workerId, { params }).pipe(
      map(res => {
        return res;
      }),
      catchError(this.handleError)
    );
  }

  getWorkerCalendarAppointments(workerId: string, startDate?: string, endDate?: string): Observable<any> {
    let params = new HttpParams();

    if (startDate) {
      params = params.set('startDate', startDate);
    }

    if (endDate) {
      params = params.set('endDate', endDate);
    }

    // Include the params object in the HTTP request
    return this.http.get(environment.apiUrl + "/workerCalendarAppointments/" + workerId, { params }).pipe(
      map(res => {
        return res;
      }),
      catchError(this.handleError)
    );
  }

  getUserAppointments(userId, status, page, limit): Observable<any> {
    let params = new HttpParams();
    params = params.append('status', status.toString());
    params = params.append('page', page.toString());
    params = params.append('limit', limit.toString());
    return this.http.get(environment.apiUrl + "/userAppointments/" + userId, { params }).pipe(map(res => { return res }), catchError(this.handleError))
  }

  getUserAppointment(appointmentId): Observable<any> {
    return this.http.get(environment.apiUrl + "/userAppointment/" + appointmentId).pipe(map(res => { return res }), catchError(this.handleError))
  }

  getStatuses(): Observable<any> {
    return this.http.get(environment.apiUrl + "/statuses").pipe(map(res => { return res }), catchError(this.handleError))
  }

  getRegion(): Observable<any> {
    return this.region$.pipe(map(res => { return res }), catchError(this.handleError))
  }

  getWorkerSpecialty(): Observable<any> {
    return this.workerSpecialty$.pipe(map(res => { return res }), catchError(this.handleError))
  }

  getWorkerRatings(userId): Observable<any> {
    return this.http.get(environment.apiUrl + "/workerRatings/" + userId).pipe(map(res => { return res }), catchError(this.handleError))
  }

  getWorkerRating(workerId, skip, limit): Observable<any> {
    return this.http.get(environment.apiUrl + "/workerRating/" + workerId + '?skip=' + skip + '&limit=' + limit)
      .pipe(map(res => { return res }), catchError(this.handleError))
  }

  getTotalRatings(workerId): Observable<any> {
    return this.http.get(environment.apiUrl + "/totalRatings/" + workerId)
      .pipe(map(res => { return res }), catchError(this.handleError))
  }

  getCheckUserRating(userId, workerId): Observable<any> {
    return this.http.get(environment.apiUrl + "/checkRating/" + userId + "/" + workerId).pipe(map(res => { return res }), catchError(this.handleError))
  }

  getSearchWorkers(workerSpecialty, region, name, page: number, limit: number): Observable<any> {
    let params = new HttpParams();
    if (region) params = params.append('region', region);
    if (workerSpecialty) params = params.append('workerSpecialty', workerSpecialty);
    if (name) params = params.append('name', name);
    params = params.append('page', page.toString());
    params = params.append('limit', limit.toString());
    return this.http.get(environment.apiUrl + "/searchWorkers", { params }).pipe(map(res => { return res }), catchError(this.handleError))
  }

  getImage(filename: string): Observable<Blob> {
    const url = `/image/${filename}`;
    return this.http.get(environment.apiUrl + url, { responseType: 'blob' });
  }

  postRateWorker(data): Observable<any> {
    return this.http.post<any>(environment.apiUrl + '/rateWorker', data)
      .pipe(map(res => {
        Swal.fire({
          title: 'Създадени Часове за запазване.',
          icon: 'success',
          confirmButtonColor: '#3F51B5',
          allowOutsideClick: false,
        });
        return res
      }), catchError(this.handleError))
  }

  postWorkerSpecalties(data): Observable<any> {
    let specialty = { workerSpecialty: data }
    return this.http.post<any>(environment.apiUrl + '/workerSpecialty', specialty)
      .pipe(map(res => {
        Swal.fire({
          title: 'Успешно добавяне на специалност',
          icon: 'success',
          confirmButtonColor: '#3F51B5',
          allowOutsideClick: false,
        });
        const specialty = this.workerSpecialtySubject.getValue(); specialty.push(res); this.workerSpecialtySubject.next(specialty); return res
      }), catchError(this.handleError))
  }

  postRegion(data): Observable<any> {
    let region = { region: data }
    return this.http.post<any>(environment.apiUrl + '/region', region)
      .pipe(map(res => {
        Swal.fire({
          title: 'Успешно добавяне на регион',
          icon: 'success',
          confirmButtonColor: '#3F51B5',
          allowOutsideClick: false,
        });
        const regions = this.regionSubject.getValue(); regions.push(res); this.regionSubject.next(regions); return res
      }), catchError(this.handleError))
  }

  deleteUser(userId: string, changes: Partial<User>): Observable<any> {
    const users = this.usersStoreSubject.getValue();
    const index = users.findIndex(user => user._id == userId)
    if (index > -1) {// only splice array when item is found
      users.splice(index, 1); // 2nd parameter means remove one item only
    }
    this.usersStoreSubject.next(users)
    return this.http.delete(environment.apiUrl + '/delete-user/' + userId).pipe(shareReplay(), map(res => { return res }), catchError(this.handleError))
  }

  deleteRegion(regionId: string): Observable<any> {
    const regions = this.regionSubject.getValue(); const index = regions.findIndex(region => region._id == regionId);
    if (index > -1) regions.splice(index, 1)
    this.regionSubject.next(regions)
    return this.http.delete(environment.apiUrl + '/delete-region/' + regionId).pipe(shareReplay(), map(res => { return res }), catchError(this.handleError))
  }

  deleteWorkerSpecialty(workerSpecialtyId: string): Observable<any> {
    const specialties = this.workerSpecialtySubject.getValue(); const index = specialties.findIndex(specialty => specialty._id == workerSpecialtyId);
    if (index > -1) specialties.splice(index, 1)
    this.workerSpecialtySubject.next(specialties)
    return this.http.delete(environment.apiUrl + '/delete-workerSpecialty/' + workerSpecialtyId).pipe(shareReplay(), map(res => { return res }), catchError(this.handleError))
  }

  deleteAppointments(workerId: string, date: string): Observable<any> {
    const data = {
      workerId: workerId,
      date: date,
    };

    return new Observable((observer) => {
      Swal.fire({
        title: `Изтриване на <strong>ВСИЧКИ</strong> Активни часове за
        <br> <strong>${date}</strong>?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Изтрии',
        confirmButtonColor: 'red',
        cancelButtonColor: '#005792',
        cancelButtonText: 'Отказ',
        preConfirm: () => {
          return this.http
            .request('delete', environment.apiUrl + '/deleteAppointments', { body: data })
            .toPromise();
        },
      }).then((result) => {
        if (result.isConfirmed) {
          const res = result.value;
          const currentData = this.appointmentsDataSubject.getValue();
          const updatedData = currentData.filter(
            (appointment) =>
              appointment.workerId !== workerId || appointment.date !== date || appointment.status !== 'Активен',
          );
          this.appointmentsDataSubject.next(updatedData);
          Swal.fire({
            title: `Успешно изтрихте всички <strong>Активни</strong> часове за <strong>${date}</strong>?`,
            icon: 'success',
            confirmButtonColor: '#005792',
          });
          observer.next(res);
          observer.complete();
        } else {
          observer.error(result.dismiss);
        }
      });
    });
  }

  postContactForm(formData: any): Observable<any> {
    return this.http.post<any>(environment.apiUrl + '/contact-form', formData)
      .pipe(map(res => {
        Swal.fire({
          title: 'Успешно изпратен Имейл.',
          icon: 'success',
          confirmButtonColor: '#3F51B5',
          allowOutsideClick: false,
        });
        return res;
      }), catchError(this.handleError));
  }


  sortAppointments(appointments: any[]): any[] {
    return appointments.sort((a, b) => {
      return a.slotTimeStart.localeCompare(b.slotTimeStart);
    });
  }

  confirmAlert(title, icon, confirmButtonColor, confirmButtonText) {
    return Swal.fire({
      title: title,
      icon: icon,
      showCancelButton: true,
      confirmButtonColor: confirmButtonColor,
      cancelButtonColor: '#3F51B5',
      confirmButtonText: confirmButtonText
    })
  }

  successAlert(title, html) {
    return Swal.fire({
      title: title,
      html: html,
      icon: 'success',
      confirmButtonColor: '#3F51B5',
    })
  }

  public handleError(error: Response | any) {
    let errMsg = ""
    if (error.error.message) errMsg = error.error.message
    else errMsg = error.statusText
    Swal.fire({
      title: "Грешка",
      icon: 'error',
      text: errMsg,
      allowOutsideClick: false,
      showConfirmButton: true,
      confirmButtonColor: 'blue'
    })
    return observableThrowError(errMsg);
  }

}
