import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AuthInterceptor } from './auth/auth-interceptor';
import { AppComponent } from './app.component';
import { AngularMaterialModule } from './angular-material.module';
import { HeaderComponent } from './header/header.component';
import { AuthGuard } from './auth/auth.guard';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';

import localeBg from '@angular/common/locales/bg';
import { FooterComponent } from './footer/footer.component';
import { TawkToComponent } from './livechat/tawk-to/tawk-to.component';
import { MatDialogModule } from '@angular/material/dialog';
import { GdprComponent } from './footer/gdpr/gdpr.component';
import { GeneralConditionsComponent } from './footer/general-conditions/general-conditions.component';
import { CookiesComponent } from './footer/cookies/cookies.component';

registerLocaleData(localeBg);

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    TawkToComponent,
    GdprComponent,
    GeneralConditionsComponent,
    CookiesComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AngularMaterialModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule
  ],
  providers: [
    AuthGuard,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    { provide: LOCALE_ID, useValue: 'bg-BG' },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }