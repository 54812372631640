import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { throwError as observableThrowError, Observable, Subject, BehaviorSubject } from "rxjs";
import { map, catchError, tap } from "rxjs/operators";

import { AuthData } from './auth-data.model';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { MatDialog } from '@angular/material/dialog';

const BACKEND_URL = environment.apiUrl + '/';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private isAuthenticated = false;
  private token: any;
  private tokenTimer: any;
  private userId: any;
  private role: any;
  private authStatusListener = new Subject<boolean>();
  private intendedRoute: string | null = null;
  private alertsKeyword: string;
  showLogoutConfirmation: boolean = false;
  postSaved = new BehaviorSubject<boolean>(false);

  sessionExpired: boolean = false;

  private dataSubject = new Subject<any>();
  data$ = this.dataSubject.asObservable();

  constructor(private http: HttpClient, private router: Router, private dialog: MatDialog) {

  }

  getToken() {
    return this.token;
  }

  getIsAuth() {
    return this.isAuthenticated;
  }

  getUserId() {
    return this.userId;
  }

  getAuthStatusListener() {
    return this.authStatusListener.asObservable();
  }

  setIntendedRoute(url: string): void {
    this.intendedRoute = url;
  }

  getIntendedRoute(): string | null {
    return this.intendedRoute;
  }

  clearIntendedRoute(): void {
    this.intendedRoute = null;
  }

  createUser(email: string, password: string, name: string, family: string, mobileNumber: number, region: string, domain: string): Observable<any> {
    this.alertsKeyword = 'signup';
    this.loadingAlert(this.alertsKeyword, email)
    const authData = {
      email: email,
      password: password,
      name: name + " " + family,
      mobileNumber: mobileNumber,
      region: region,
      env: domain
    };
    return this.http
      .post(BACKEND_URL + 'signup', authData).pipe(tap(response => {
        this.successAlert(this.alertsKeyword, email);
      }, error => {
        this.authStatusListener.next(false)
        this.errorMessageAlert(error);
      })
      )
  }

  verifyEmail(token: string): Observable<any> {
    return this.http.get(environment.apiUrl + `/verify-email?token=${token}`).pipe(map(res => { 
      Swal.fire({
        title: 'Успешно верифициран емайл!',
        icon: 'success',
        confirmButtonColor: '#3F51B5',
        allowOutsideClick: false,
      });
      this.router.navigate(['/auth/login']);
      return res }), catchError(err => this.handleError(err)))
  }

  resendEmailVerification(email, domain) {
    let data = {
      env: domain,
      email: email
    }
    return this.http
      .post(BACKEND_URL + 'resend-verification-email', data).subscribe((response) => {
        Swal.fire({
          title: "Емайл изпратен успешно.",
          html: "Моля проверете <strong>" + email + "</strong>, за да верифицирате емайла си.",
          icon: 'success',
          confirmButtonColor: '#3F51B5',
          allowOutsideClick: false,
        });
      }, error => {
        this.errorMessageAlert(error);
      });
  }

  login(email: string, password: string): Observable<any> {
    const authData: AuthData = { email: email, password: password };
    return this.http
      .post<{ token: string, expiresIn: number, userId: string, role: string, emailVerified: boolean }>(BACKEND_URL + 'login', authData)
      .pipe(
        tap(response => {
          const token = response.token;
          this.token = token;
          if (token) {
            const expiresInDuration = response.expiresIn;
            this.setAuthTimer(expiresInDuration)
            this.isAuthenticated = true;
            this.userId = response.userId;
            this.role = response.role;
            this.authStatusListener.next(true);
            const now = new Date();
            const expirationDate = new Date(now.getTime() + expiresInDuration * 1000);
            this.saveAuthData(token, expirationDate, this.userId, this.role)
            this.dataSubject.next(response.role);
          }

        }, error => {
          this.authStatusListener.next(false);
          this.errorMessageAlert(error);
        })
      );
  }
  
  resetPassword(email, domain) {
    let data = {
      env: domain,
      email: email
    }
    return this.http
      .post(BACKEND_URL + 'reset-password', data).subscribe((response) => {
        this.router.navigate(['/auth/login']);
        Swal.fire({
          title: "Емайл изпратен успешно.",
          html: "Моля проверете <strong>" + email + "</strong>, за да верифицирате емайла си.",
          icon: 'success',
          confirmButtonColor: '#3F51B5',
          allowOutsideClick: false,
        });
      }, error => {
        this.errorMessageAlert(error);
      });
  }

  getNewPasswordTokenData(token): Observable<any> {
    return this.http.get(environment.apiUrl + "/new-password/" + token).pipe(map(res => { return res }))
  }

  postNewPassword(data): Observable<any> {
    return this.http.post<any>(environment.apiUrl + '/new-password', data).pipe(map(res => { return res }), catchError(err => this.handleError(err)))
  }

  //This is needed so we do not lose our authentication on refresh
  autoAuthUser() {
    const authInformation = this.getAuthData();
    if (!authInformation) {
      return;
    }
    const now = new Date();
    const expiresIn = authInformation.expirationDate.getTime() - now.getTime();
    if (expiresIn > 0) {
      this.token = authInformation.token;
      this.isAuthenticated = true;
      this.userId = authInformation.userId;
      this.role = authInformation.role
      this.setAuthTimer(expiresIn / 1000)
      this.authStatusListener.next(true);
    }
  }

  async logout() {
    this.token = null;
    this.isAuthenticated = false;
    this.authStatusListener.next(false);
    clearTimeout(this.tokenTimer);
    sessionStorage.clear()
    this.clearAuthData();
    this.userId = null;
    // Set sessionExpired to true before navigating
    this.sessionExpired = true;
    // Wait for the navigation to complete
    await this.router.navigate(['/auth/login']);
    // Reset sessionExpired to false after navigation is done
    this.sessionExpired = false;
  }

  private setAuthTimer(duration: number) {
    this.tokenTimer = setTimeout(() => {
      this.dialog.closeAll();
      this.logout();
      this.sessionExpiredAlert();
    }, duration * 1000);
  }

  private saveAuthData(token: string, expirationDate: Date, userId: string, role: string) {
    localStorage.setItem("token", token);
    localStorage.setItem("expiration", expirationDate.toISOString())
    localStorage.setItem("userId", userId)
    localStorage.setItem("role", role)
  }

  private clearAuthData() {
    localStorage.removeItem("token")
    localStorage.removeItem("expiration")
    localStorage.removeItem("userId")
    localStorage.removeItem("role")
  }

  private getAuthData() {
    const token = localStorage.getItem("token");
    const expirationDate = localStorage.getItem("expiration");
    const userId = localStorage.getItem("userId")
    const role = localStorage.getItem("role")
    if (!token || !expirationDate) {
      return null;
    }
    return {
      token: token,
      expirationDate: new Date(expirationDate),
      userId: userId,
      role: role
    }
  }
  errorMessageAlert(error) {
    if (error.error.message === 'Email not verified') {
      Swal.close();
      return;
    }

    Swal.fire({
      title: 'Възникна грешка.',
      icon: 'error',
      html: '<strong>' + error.error.message + '</strong>',
      confirmButtonColor: '#3F51B5',
      allowOutsideClick: false,
    });
  }

  loadingAlert(alertsKeyword, email) {
    let title, text;
    if (alertsKeyword === 'login') {
      title = 'Logging... ';
      text = 'Please wait a few seconds.'
    } else if (alertsKeyword === 'signup') {
      title = 'Creating user with ' + email + ' address...';
      text = 'Please wait a few seconds.'
    };
    Swal.fire({
      title: title,
      text: text,
      allowOutsideClick: false,
    });
    Swal.showLoading();
  }

  sessionExpiredAlert() {
    Swal.fire({
      title: "Сесията ви изтече",
      icon: 'info',
      html: "Моля влезте отново",
      confirmButtonColor: '#3F51B5',
      allowOutsideClick: false,
    });
  }

  successAlert(alertsKeyword, email) {
    let title, html;
    if (alertsKeyword === 'login') {
      title = 'Добре дошли!';
      html = '';
    } else if (alertsKeyword === 'signup') {
      title = 'Успешно създаване';
      html = 'Потребител <strong>' + email + '</strong> беше създаден.';
    }
    Swal.fire({
      title: title,
      icon: 'success',
      confirmButtonColor: '#3F51B5',
      allowOutsideClick: false,
    });
    Swal.fire({
      title: title,
      icon: 'success',
      html: html,
      confirmButtonColor: '#3F51B5',
      allowOutsideClick: false,
    });
  }

  public handleError(error: Response | any) {
    let errMsg = error.error.message
    Swal.fire({
      title: "Възникна грешка.",
      icon: 'error',
      text: errMsg,
      allowOutsideClick: false,
      showConfirmButton: true,
      confirmButtonColor: 'blue'
    })
    this.router.navigate(['/auth/login']);

    return observableThrowError(errMsg);
  }

}