<nav class="navbar navbar-expand-lg primary">
    <div class="container-fluid">
      <a class="navbar-brand logo" routerLink="/">Супер Майстори</a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
    <span class="spacer"></span>
        
        <ul class="navbar-nav ml-auto">
          <li class="nav-item" *ngIf="userIsAuthenticated && role!='admin'">
            <a class="nav-link" routerLink="/profile" routerLinkActive="accent">Профил</a>
          </li>
          <li class="nav-item" *ngIf="userIsAuthenticated && role=='admin'">
            <a class="nav-link" routerLink="/admin/users" routerLinkActive="accent">Потребители</a>
          </li>
          <li class="nav-item" *ngIf="userIsAuthenticated && role=='admin'">
            <a class="nav-link" routerLink="/admin/panel" routerLinkActive="accent">Панел</a>
          </li>
          <li class="nav-item" *ngIf="!userIsAuthenticated">
            <a class="nav-link" routerLink="/auth/login" routerLinkActive="accent">Вход</a>
          </li>
          <li class="nav-item" *ngIf="!userIsAuthenticated">
            <a class="nav-link" routerLink="/auth/signup" routerLinkActive="accent">Регистрация</a>
          </li>
          <li class="nav-item" *ngIf="userIsAuthenticated">
            <a class="nav-link" (click)="onLogout()">Излизане</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  