<footer class="footer">
  <div class="footer-icons">
    <a href="https://www.instagram.com/supermaistori/" target="_blank" title="Instagram"
      class="fab fa-instagram fa-2x"></a>
    <a href="https://www.facebook.com/profile.php?id=100095153140875" target="_blank" title="Facebook"
      class="fab fa-facebook-f fa-2x"></a>
    <!-- <a href="https://www.twitter.com" target="_blank" title="Twitter" class="fab fa-twitter fa-2x"></a> -->
  </div>

  <div class="footer-terms-and-conditions">
    <div class="item">
      <a class="nav-link" (click)="scrollToTop()" routerLink="/general-conditions" routerLinkActive="accent">Общи
        условия</a>
    </div>
    <div class="item">
      <a class="nav-link" (click)="scrollToTop()" routerLink="/cookies" routerLinkActive="accent">Бисквитки</a>
    </div>
    <div class="item">
      <a class="nav-link" (click)="scrollToTop()" routerLink="/gdpr" routerLinkActive="accent">Поверителност</a>
    </div>
  </div>
  <p class="footer-text">&copy; 2024 Supermaistori. All rights reserved.</p>
</footer>