import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-tawk-to',
  template: '',
})
export class TawkToComponent implements OnInit, OnDestroy {
  private readonly tawkToScriptId = 'tawkto-script';
  private readonly tawkToSnippet = `
  var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
  (function(){
    var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
    s1.async=true;
    s1.src='https://embed.tawk.to/642fbde34247f20fefea4ed4/1gtd7rg8l';
    s1.charset='UTF-8';
    s1.setAttribute('crossorigin','*');
    Tawk_API.onLoad = function(){Tawk_API.setLanguage('bg');};
    s0.parentNode.insertBefore(s1,s0);
  })();`;

  ngOnInit() {
    if (!document.getElementById(this.tawkToScriptId)) {
      const tawkToScript = document.createElement('script');
      tawkToScript.id = this.tawkToScriptId;
      tawkToScript.type = 'text/javascript';
      tawkToScript.async = true;
      tawkToScript.appendChild(document.createTextNode(this.tawkToSnippet));
      document.head.appendChild(tawkToScript);
    }
  }

  ngOnDestroy() {
    const tawkToScript = document.getElementById(this.tawkToScriptId);
    if (tawkToScript) {
      document.head.removeChild(tawkToScript);
    }
  }
}
