import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { User } from '../models/user.model';
import { Services } from '../services/services.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  user: any = [];
  userIsAuthenticated = false;
  userId = localStorage.getItem("userId")!;
  role = localStorage.getItem("role")!;
  private authListenerSubs: Subscription;
  private roleSubscription: Subscription;
  
  constructor(private authService: AuthService,private svc:Services, private route: ActivatedRoute) {
    

  }

  ngOnInit(): void {
    this.userIsAuthenticated = this.authService.getIsAuth();
    this.authListenerSubs = this.authService
      .getAuthStatusListener()
      .subscribe((isAuthenticated) => {
        this.userIsAuthenticated = isAuthenticated;
      });

      this.roleSubscription = this.authService.data$.subscribe(newRole => {
        this.role = newRole;
      });
  }

  onLogout() {
    this.authService.postSaved.next(true);
    this.authService.logout();
  }

  ngOnDestroy() {
    this.authListenerSubs.unsubscribe()
    if (this.roleSubscription) { // Unsubscribe from role changes to prevent memory leaks
      this.roleSubscription.unsubscribe();
    }
  }
}
