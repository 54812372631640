import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router'; 
const routes: Routes = [
  // { path: '', component: HomePageComponent, canActivate:[AuthGuard] },
  // The above was commented out and most likely the below worker path that is currently like '' will need to have some text
  // for the worker(user) to be on the same page and for SEO optimization
  { path: '', loadChildren: () => import("./worker/worker.module").then(m => m.WorkerModule)},
  { path: 'admin', loadChildren: () => import("./admin/admin.module").then(m => m.AdminModule)},
  { path: 'auth', loadChildren: () => import("./auth/auth.module").then(m => m.AuthModule)},
  { path: 'blogs', loadChildren: () => import('./blogs/blogs.module').then(m => m.BlogsModule) }


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
